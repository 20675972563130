/* eslint-disable react/jsx-indent */
import React, { useState } from 'react';
import { graphql } from 'gatsby';

import ImageFromField from '../components/global/ImageFromField';
import Layout from '../components/layout/Layout';
import Link from '../components/global/Link';
import EmailForm from '../components/layout/landingPage/EmailForm';
import ArtworkCard from '../components/global/ArtworkCard';
import useAuth from '../util/hooks/useAuth';
import dateFormat from '../components/helper/dateFormat';
import SEO from '../components/SEO';
import returnWithFallback from '../util/helper/returnWithFallback';
import { ReadMore } from '../components/global/ReadMore';

const ExhibitionSinglePage = ({ data, pageContext }) => {
  useAuth();
  const [device, setDevice] = useState('mobile');
  const { field_dates, relationships } = pageContext;
  const { field_locations_ref: locations, field_artwork_ref } = relationships;
  const { edges: nodeArtworks } = data.allNodeArtwork;

  // compare ids to match field_artwork_ref sorting
  let artworks = field_artwork_ref.map((art) => nodeArtworks.find(({node: n}) => n.drupal_id === art.drupal_id));
  artworks = artworks.filter((r) => r);

  const { field_featured_image, field_exhib_artist_ref: artists } =
    data.nodeExhibition.relationships;

  const title = data.nodeExhibition.title
    ? data.nodeExhibition.title
    : 'Loch Gallery Exhibition';

  let virtualTour = data.nodeExhibition?.relationships?.field_virtual_tours;
  if (virtualTour.length) {
    virtualTour = virtualTour[0];
  }
  return (
    <Layout
      device={device}
      setDevice={setDevice}
      logo={data.logo}
      menu={data.allMenuMenuLink}
      locations={data.locations.edges}
      footerMenu={data.footer_menu}
      className="exhibition-single"
    >
      <SEO title={title}  image={field_featured_image?.uri?.url} />
      <section className="section ">
        <div className="container">
          <h1 className="h2 t-heading t-uppercase">{title}</h1>
        </div>
      </section>
      <section className="section t-left exhibition-info">
        <div className="container">
          <div className="columns">
            <div className="column is-two-thirds">
              <ImageFromField
                img={returnWithFallback({
                  fallback: field_featured_image,
                  optional: 'image_style_uri',
                  list_key: 'featured_image_crop',
                  list: true,
                })}
                fallback={'./media/logo.png'}
                direct={true}
                style={{
                  maxHeight: '500px',
                  minHeight: '110px',
                  width: 'auto',
                  height: 'auto',
                  objectFit: 'contain',
                }}
                className="exh-banner"
              />
            </div>
            <div className="column is-one-thrid">
              <div className="exhibition-details">
                <ul className="exhibition-artist">
                  <h4>Artist(s)</h4>
                  <div className="exh-artist-columns">
                    {artists && artists.length > 0
                      ? artists.map((artist) => (
                          <Link path={artist.path?.alias}>
                            <li>{artist.title}</li>
                          </Link>
                        ))
                      : null}
                  </div>
                </ul>
                <ul className="exhibition-date">
                  <h4>Dates</h4>
                  {field_dates && field_dates.value && field_dates.end_value ? (
                    <li>
                      {dateFormat(field_dates.value, 1)} -{' '}
                      {dateFormat(field_dates.end_value, 1)}
                    </li>
                  ) : (
                    <li>TBD</li>
                  )}
                </ul>
                <ul className="exhibition-locations">
                  <h4>Locations</h4>
                  {locations && locations.length > 0
                    ? locations.map((location) => <li>{location.title}</li>)
                    : null}
                  {virtualTour?.id ? (
                    <li style={{marginTop: '25px'}}><Link className="button" path={`/virtual-tours?id=${virtualTour?.id}`}>Virtual Tour</Link></li>
                  ) : null}
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="spacer"></div>
          {data.nodeExhibition?.body?.processed && data.nodeExhibition?.body?.processed.length > 500 ? (
            <div className={`exhibition-content`}>
              <ReadMore>
                <div dangerouslySetInnerHTML={{ __html: data.nodeExhibition?.body?.processed }}></div>
              </ReadMore>
            </div>
          ) : (
            <div className="exhibition-content view-more">
              <div dangerouslySetInnerHTML={{ __html: data.nodeExhibition?.body?.processed }}></div>
            </div>
          )}
        </div> 
      </section>

      {artworks && artworks.length > 0 ? (
        <section className="section artwork-info artist-info">
          <div className="container">
            <div className="spacer"></div>
            <div className="artworks grid-3">
              {artworks.map(({node: artwork}, i) =>
                artwork.path ? (
                  <ArtworkCard
                    artwork={artwork}
                    device={device}
                    key={artwork + i}
                    artist_drupal_id={
                      artwork?.relationships?.field_artist_ref?.drupal_id
                    }
                    className="grid-col"
                  />
                ) : null
              )}
            </div>
            <div className="spacer"></div>
          </div>
        </section>
      ) : (
        <> </>
      )}
      <EmailForm />
    </Layout>
  );
};

export default ExhibitionSinglePage;

export const ExhibitionSingleQuery = graphql`
  query ExhibitionSingleQuery($pageId: String, $the__drupal_id: String) {
    exhibitionVirtualTours: nodeVirtualTour(relationships: {field_exhibition: {drupal_id: {eq: $the__drupal_id}}}) {
      relationships {
        field_exhibition {
          title
        }
      }
      id
    }
    nodeExhibition(id: { eq: $pageId }) {
      body {
        processed
      }
      relationships {
        field_featured_image {
          uri {
            url
          }
          image_style_uri {
            featured_image_crop
          }
        }
        field_artwork_ref {
          drupal_id
        }
        field_virtual_tours {
					title
          id
        }
        field_exhib_artist_ref {
          title
          path {
            alias
          }
        }
        field_locations_ref {
          title
        } 
      }
      field_dates {
        end_value
        value
      }
      path {
        alias
      }
      title
    }
    allNodeArtwork(
      filter: {
        relationships: {
          node__exhibition: { elemMatch: { id: { eq: $pageId } } }
        }
        status: { eq: true }
      }
      sort: { fields: created, order: DESC }
    ) {
      edges {
        node {
          drupal_id
          field_artwork_name
          field_year_or_circa
          status
          relationships {
            field_location_ref {
              title
            }
            field_artwork_images {
              uri {
                url
              }
              image_style_uri {
                gatsby_artwork_image
                gatsby_artwork_thumbnail
                gatsby_landing_page_banner
              }
            }
            field_artist_ref {
              title
              id
              drupal_id
              path {
                alias
              }
              relationships {
                field_artist_type {
                  name
                }
                field_artist_origin {
                  name
                }
              }
            }
            field_artwork_type {
              name
            }
            field_artwork_medium {
              name
            }
            field_artwork_status {
              name
            }
            field_artwork_subject {
              name
            }
          }
          path {
            alias
          }
          field_depth
          field_height
          field_width
          field_dimensions_computed
          field_price_avail_on_request
          field_art_price_original {
            currency_code
            number
          }
        }
      }
    }
    allMenuMenuLink(
      sort: { fields: [weight], order: ASC }
      filter: { menu_name: { eq: "gatsby-menu" } }
    ) {
      edges {
        node {
          enabled
          title
          expanded
          external
          langcode
          weight
          link {
            uri
          }
          drupal_parent_menu_item
          bundle
          drupal_id
          menu_name
        }
      }
    }
    footer_menu: allMenuMenuLink(
      sort: { fields: [weight], order: ASC }
      filter: { menu_name: { eq: "footer" } }
    ) {
      edges {
        node {
          enabled
          title
          expanded
          external
          langcode
          weight
          link {
            uri
          }
          bundle
          drupal_id
          menu_name
        }
      }
    }
    locations: allNodeLocation {
      edges {
        node {
          field_address {
            address_line1
            address_line2
            country_code
            locality
            postal_code
          }
          field_address_description
          field_address_short
          field_location_email
          field_location_fax_number
          field_location_phone_number
          title
          field_office_hours_description
        }
      }
    }
  }
`;
